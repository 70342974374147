a,
a.read-more,
.btn-dropdown {
  color: $site-link-color;

  &:hover,
  &:focus {
    color: $site-link-color-hover;
  }
}
