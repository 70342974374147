//megamenu mobile definition
.navbar {
  .navbar-collapsable {
    .menu-wrapper {
      .it-brand-wrapper {
        background-color: $primary;

        .icon {
          width: 80px;
          height: 80px;
        }

        h2 {
          max-width: 11em;
          color: $secondary-text;
          font-size: 1.3em;
          font-weight: 400;

          text-transform: uppercase;
        }
      }
    }
  }
}
