.calendar {
  .calendar-body {
    height: 500px;
    .body {
      height: 500px;
      .day {
        font-size: 1.8rem;
      }
      .day,
      .day-week {
        line-height: 1.2;
      }
    }
  }
}
