//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .it-header-navbar-wrapper {
    border-top: 2px solid #fff;
    background: $secondary;
  }
}

//Desktop
@media (min-width: #{map-get($grid-breakpoints, xl)}) {
}
