//site
$primary-h: 197 !default; //210 !default;
$primary-s: 100 !default;
$primary-b: 87 !default; //80 !default;

$site-primary: #009fde;

$secondary: #071d49;
$tertiary: #009fde;
$tertiary-text: #fff;

//$header-bg-color: $monet-blue;
//$header-center-bg-color: $header-bg-color;

//$headings-color: $primary;
//$semibold: 600;
$site-link-color: darken($site-primary, 18);
$site-link-color-hover: darken($site-primary, 20);
