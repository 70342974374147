.navbar {
  // navbar mobile
  .navbar-collapsable {
    .menu-wrapper .it-brand-wrapper {
      .it-brand-text {
        display: none;
      }

      a .icon {
        width: auto;
        max-width: 100%;
        margin: 0;
      }
    }

    /*lista menu*/
    .navbar-nav {
      li {
        a.nav-link {
          color: $secondary;
        }
      }
    }
  }

  // Dropdown style tipo collapse
  .dropdown-menu {
    .link-list-wrapper {
      ul li {
        a {
          span {
            color: $site-link-color;
          }

          i {
            color: $site-link-color;
          }
        }
      }
    }
  }
}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .navbar {
    // navbar mobile
    .navbar-collapsable {
      /*lista menu*/
      .navbar-nav {
        li {
          a.nav-link {
            color: $primary-text;
          }
        }
      }
    }
    // Dropdown style tipo collapse
    .dropdown-menu {
      .link-list-wrapper {
        ul li {
          a {
            span,
            i,
            h3 {
              color: $site-link-color;
            }
          }
        }

        .link-list {
          &.bordered {
            li {
              border-color: transparent;

              &:hover,
              &:active {
                background-color: $neutral-1-a1;
              }
            }
          }

          .it-more {
            a {
              span,
              i,
              h3 {
                color: $secondary;
              }

              svg {
                fill: $secondary;
              }
            }
          }
        }
      }
    }
  }
}
