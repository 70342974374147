.scroll-to-top {
  .btn {
    top: 2rem;
    bottom: unset;

    //small - tablet
    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      top: 2rem;
      bottom: unset;
    }
  }
}
