//mobile
.it-header-center-wrapper {
  .it-header-center-content-wrapper {
    .it-brand-wrapper {
      padding-left: 40px;

      a {
        h2 {
          max-width: 11em;
          font-size: 1.3em;
          font-weight: 400;
          text-transform: uppercase;
        }

        .icon {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media (min-width: #{map-get($grid-breakpoints, md)}) {
  .it-header-center-wrapper {
    .it-header-center-content-wrapper {
      .it-brand-wrapper {
        padding-left: 32px;
      }
    }
  }
}
