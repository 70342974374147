$footer-underline-color: #96a7b8;

.it-footer {
  .it-footer-main {
    background-color: $secondary;

    section.border-white.border-top {
      border: none !important;
    }

    .btn-primary {
      @extend .btn-tertiary;
    }

    h4 {
      padding-bottom: 0.5rem;
      border-bottom: 0.5px solid $footer-underline-color;
      margin-right: 0.5em;

      a {
        font-size: 14px;
      }

      + .link-list-wrapper {
        display: none;
      }
    }

    .it-brand-wrapper {
      .it-brand-text {
        display: none;
      }

      a {
        .icon {
          width: auto;
          height: $header-center-icon-size;
          margin: 0;

          img {
            max-height: 100%;
          }
        }
      }
    }

    .it-socials {
      > span {
        display: none;
      }
    }

    .social {
      .list-inline-item:not(:last-child) {
        margin-right: 0.4rem;
      }

      .list-inline-item {
        margin-bottom: 0.5rem;
      }
    }

    .link-list-wrapper {
      ul {
        li {
          a {
            text-decoration-color: $footer-underline-color;
          }
        }
      }
    }

    .news-wrapper {
      display: flex;

      img {
        max-width: 75px;
        filter: brightness(0) invert(1);
      }
    }
  }
}

.it-footer-small-prints {
  background-color: $secondary;
  font-weight: 400;

  .container {
    border-top: 1px solid $footer-underline-color;

    a {
      text-decoration: underline;
      text-decoration-color: $footer-underline-color;

      &:hover {
        text-decoration: underline;
        text-decoration-color: $white;
      }
    }
  }
}
